import { ChakraProvider } from '@chakra-ui/react';
import { Global } from '@emotion/react';
import { UserContextProvider } from '@shared/common';
import { AnimatePresence } from 'framer-motion';
import { FC, ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { config } from './config';
import { DEFAULT_LEAD_VALUE } from './constants';
import ErrorBoundary from './ErrorBoundary';
import { useLeadQuery } from './hooks/use-lead-query';
import { useAppLead } from './hooks/useAppLead';
import { useAppRouter } from './hooks/useAppRouter';
import { useLeadUpdateMutation } from './hooks/useLeadUpdateMutation';
import { useTracking } from './hooks/useTracking';
import { fonts, getTheme } from './theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const GlobalProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { funnelName } = useAppRouter();

  useEffect(() => {
    document.body.classList.add(fonts.switzer.variable);
  }, []);

  return (
    <ErrorBoundary>
      <ChakraProvider theme={getTheme(funnelName)} resetCSS={false}>
        <Global styles={GOBAL_STYLES} />
        <UserContextProvider storageKey={config.NAME} defaultUserData={DEFAULT_LEAD_VALUE}>
          <AnimatePresence mode='wait' onExitComplete={() => window.scrollTo(0, 0)}>
            <QueryClientProvider client={queryClient}>
              <TrackingProvider>{children}</TrackingProvider>
            </QueryClientProvider>
          </AnimatePresence>
        </UserContextProvider>
      </ChakraProvider>
    </ErrorBoundary>
  );
};

const TrackingProvider: FC<{ children: ReactNode }> = ({ children }) => {
  useLeadQuery();
  const { isPathCheckout, isPathPlans, isPathShipping } = useAppRouter();
  const { visitPlans, visitShipping, visitCheckout } = useTracking();
  const { id } = useAppLead();
  const { mutate } = useLeadUpdateMutation();

  useEffect(() => {
    if (isPathCheckout) {
      visitCheckout();

      if (id) {
        mutate({ id, customFields: { initiatedCheckout: true } });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPathCheckout]);

  useEffect(() => {
    if (isPathPlans) {
      visitPlans();
    }
  }, [isPathPlans, visitPlans]);

  useEffect(() => {
    if (isPathShipping) {
      visitShipping();
    }
  }, [isPathShipping, visitShipping]);

  return <>{children}</>;
};

const GOBAL_STYLES = `
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

input:focus-visible {
box-shadow: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type=number] {
-moz-appearance: textfield;
}
`;
