import { ShippingDetails } from '@menesko/models-harmonia';
import { useQuery } from 'react-query';

import { APIClient, getAPIUrl } from '~/firebase-init';

import { useAppLead } from './useAppLead';
import { useAppRouter } from './useAppRouter';

interface Response {
  id: string;
  email: string;
  shippingDetails?: ShippingDetails;
}

export const useLeadQuery = () => {
  const { query } = useAppRouter();
  const { updateLead } = useAppLead();

  return useQuery({
    queryFn: () => {
      return APIClient.get<unknown, Response>(getAPIUrl(`lead/${query.leadCode}`));
    },
    onSuccess: (data) => {
      updateLead(data);
    },
    enabled: Boolean(query.leadCode),
  });
};
